import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import styled from "styled-components"
import theme from "../components/layout/theme"
import BlogPostPreviewImage from "../components/BlogPostPreviewImage"
import MarkdownContent from "./MarkdownContent"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import TitleHeader from "../components/layout/TitleHeader"
import { ContentContainer, ImageContainer } from "../components/layout/ContentLayoutComponents"

const ContentWrapper = styled.div`
  margin-top: 25px;
`

export default ({ data }) => {
  const post = data.markdownRemark
  const title = post.frontmatter.title
  return (
    <Layout>
        <SEO title={title} />
        <Helmet title={title} />

        <ContentContainer>
          <TitleHeader title={title}/>

          { post.frontmatter.titleImageName && 
          <ImageContainer>
            <BlogPostPreviewImage imageName={post.frontmatter.titleImageName}/>
          </ImageContainer>}

          <ContentWrapper>
            <MarkdownContent htmlData = { post.html } />  
          </ContentWrapper>
        </ContentContainer>
    </Layout>
  );
};

export const query = graphql`
  query SiteQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        titleImageName
      }
    }
  }
`;
